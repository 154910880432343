<template>
  <div>
    <heard-and-footer v-on:getTitleName="getTitleName">
      <title-name slot="title" :my-name="title" color="#333333"
                  :my-background="require('../../assets/image/zilv.png')"></title-name>
      <div slot="content">
        <router-view></router-view>
      </div>
    </heard-and-footer>
  </div>
</template>

<script>
import heardAndFooter from "@/components/public/heardAndFooter";
import TitleName from "@/components/public/TitleName";

export default {
  name: "self-discipline",
  components: {
    heardAndFooter,
    TitleName,
  },
  data() {
    return {
      title: this.$route.query.name,
      nowPage:this.$route.query.name,
      parent1:'',
      nav: {
        parent: '行业自律',
      }
    }
  },
  methods: {
    getTitleName(val) {
      this.title = val
      this.nowPage = val
    },

  }
}
</script>

<style scoped>

</style>